import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import "./Menu.css";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", e => {
      this.setState({ show: false });
    });
  }

  toggleMenu(e) {
    e.preventDefault();
    this.setState({ show: !this.state.show });
  }

  render() {
    return (
      <nav className={"menu " + (this.state.show ? "show" : "hide")}>
        <ul className="list">
          {this.props.items.map((val, index) => {
            return (
              <li key={index} className="item">
                <a href={val.link}>{val.caption}</a>
              </li>
            );
          })}
          <li className="item last">
            <FontAwesome
              onClick={this.toggleMenu}
              className="angle-up"
              name="angle-up"
              size="lg"
            />
          </li>
        </ul>
      </nav>
    );
  }
}

export default Menu;
