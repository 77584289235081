import * as React from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';

const firebaseUIConfig = {
  signInSuccessUrl: '',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign('<your-privacy-policy-url>');
  }
}

class LoginPage extends React.Component {

  componentDidMount() {
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    this.ui.start('#firebase-ui-auth', firebaseUIConfig);
  }

  render = () => (<div id="firebase-ui-auth" />)
}

export default LoginPage;