import React from 'react';
import './TimeLine.css';

const TimeLine = ({ sort, sortKey, points }) => {

    const sorted = [...points].sort((a,b) => {
        if (sort === "asc") return a[sortKey]<b[sortKey] ? -1 : 1;
        return a[sortKey]>b[sortKey] ? -1 : 1;
    })

    return (
        <div className="timeLine">
            {
                sorted.map((val, index) => {

                    let startDate = new Date(val.startDate);
                    let endDate = val.endDate ? new Date(val.endDate) : null;

                    startDate =  startDate.toDateString().split(' ').slice(1).join(' ');
                    endDate =  endDate ? endDate.toDateString().split(' ').slice(1).join(' ') : null;

                    return (
                        <div key={index} className="point">
                            <div className="leftSide">
                                <div className="image" style={{backgroundImage: `url('${val.company.logo}')`}}></div>
                                <div>{startDate}</div>
                                {endDate ? <div>to<br/>{endDate}</div> : <div>Present</div>}
                            </div>
                            <input type="checkbox" className="read-more-state" id={'description-' + index}/>
                            <div className="rightSide">
                                {val.roleName}
                                <h3 className="title">
                                    {val.company.link ? <a href={val.company.link}>{val.company.name}</a> : val.company.name} - {val.project}
                                </h3>
                                <h4 className="subtitle"></h4>
                                <p className="description">{val.roleDescription}</p>
                                <br/>
                            </div>
                            <label htmlFor={'description-' + index} className="read-more-trigger"></label>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default TimeLine;