import React, { Component } from "react";
import { groupBy, forEach } from "lodash";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

import "./Skills.css";

const GET_SKILLS = gql`
  query getSkills {
    skills {
      id
      name
      icon
      category
    }
  }
`;

function orderArrayByAnother(arr, order) {
  // Create a map to store the index of each element in the 'order' array
  const orderMap = new Map();
  order.forEach((item, index) => {
      orderMap.set(item, index);
  });

  // Sort the 'arr' based on the order defined in 'order' array
  return arr.sort((a, b) => {
      const indexA = orderMap.has(a) ? orderMap.get(a) : Infinity;
      const indexB = orderMap.has(b) ? orderMap.get(b) : Infinity;
      return indexA - indexB;
  });
}

const categoryOrder = ['frontend', 'backend', 'cloud', 'languages', 'other'];

class Skills extends Component {
  componentDidUpdate() {
    // this.applyMovement();
  }

  applyMovement = () => {
    this.container = document.getElementsByClassName("skills");
    this.logos = this.container[0] ? this.container[0].children : [];

    for (let i = 0; i < this.logos.length; i++) {
      this.logos[i].style.setProperty("animation-delay", Math.random() + "s");
      this.logos[i].style.setProperty(
        "-moz-animation-delay",
        Math.random() + "s"
      );
    }
  };

  skillsList = (skills = []) => {
    if (!skills.length) return null;

    const byGroup = groupBy(skills, 'category');
    //console.log(byGroup)
    forEach(byGroup, (skills, category) => {
      console.log(category, skills)
    })
    return (
      <div className="skills">
        {
          orderArrayByAnother(Object.keys(byGroup), categoryOrder).map((category) => (
            <div key={category} style={{ display: 'flex', padding: '1rem' }}>
              <h3 style={{ textTransform: "capitalize" }}>{category}</h3>
              <div style={{ display: 'flex', flexWrap: "wrap"}}>
              {
                byGroup[category].map((skill, index) => (
                  <div key={index}>
                    <div className="logo-container">
                      <span
                        className="logo"
                        style={{ backgroundImage: `url('${skill.icon}')` }}
                      />
                    </div>
                    <b style={{ textTransform: 'capitalize' }}>{skill.name}</b><br/>
                  </div>
                ))
              }
              </div>
            </div>
          ))
        }
      </div>
    )

    return (
      <div className="skills">
        {skills.map((skill, index) => (
          <div key={index}>
            <div className="logo-container">
              <span
                className="logo"
                style={{ backgroundImage: `url('${skill.icon}')` }}
              />
            </div>
            <b>{skill.name}</b><br/>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Query query={GET_SKILLS}>
          {({ data, loading, error }) => {
            if (loading) return <div>Cargando</div>;
            if (error) return <p>No Skills!</p>;

            if (data.skills) {
              setTimeout(this.applyMovement, 1000);
              return this.skillsList(data.skills);
            }
          }}
        </Query>
      </div>
    );
  }
}

export default Skills;
