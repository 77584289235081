import React from "react";
import { AuthConsumer } from "../../authContext";

const Login = props => (
  <AuthConsumer>
    {({ logout }) => {
      if (props.children) return <span onClick={logout}>{props.children}</span>;

      return (
        <button
          onClick={logout}
        >
          Logout
        </button>
      );
    }}
  </AuthConsumer>
);

export default Login;
