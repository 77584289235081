import React, { useState } from "react";
import { compress } from './helpers';

const File = ({ file, onDelete }) => {
  if (!file) return null;
  return (
    <div className="file">
      <a
        className="content"
        href={file.downloadURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {file.type.includes("image") ? (
          <img
            key={file.name}
            src={file.preview}
            alt={file.name}
          />
        ) : (
            file.name
          )}
      </a>
      <div className="delete" onClick={onDelete}>
        ✖︎
      </div>
    </div>
  );
};

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const onChange = async (e) => {
  const file = e.target.files[0];
  if (!file) return null;


  const dataBlob = await compress(e);
  const dataBase64 = await getBase64(dataBlob);

  return { name: file.name, type: file.type, preview: dataBase64, dataBlob };
};

const FileUploader = ({ onFileSelected }) => {
  const [file, updateFile] = useState(null);
  const inputRef = React.createRef();

  return (
    <div>
      <File
        file={file}
        onDelete={() => {
          inputRef.current.value = "";
          updateFile(null);
        }}
      />
      <button onClick={() => inputRef.current.click()}>Select Icon</button>
      <input
        ref={inputRef}
        type="file"
        onChange={e => {
          onChange(e).then(file => {
            console.log(file)
            if (file) {
              updateFile(file);
              onFileSelected(file);
            }
          });
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUploader;
