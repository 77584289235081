import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

import {
  Label,
  Image,
  Grid,
  Input,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import AddSkill from "./AddSkill";
import DeleteSkill from "./DeleteSkill";

export const GET_SKILLS = gql`
  query getSkills {
    skills {
      id
      name
      icon
    }
  }
`;

export const Skills = () => {
  const [searchValue, setSearchValue] = useState('');

  const searchValueChange = (e, { value }) => {
    setSearchValue(value);
  }

  const searchFilter = (skill) => {
    if (!searchValue) return skill;
    if (skill.name.startsWith(searchValue)) return skill;
    return false;
  }

  return (
    <Grid columns={2}>
      <Grid.Column>
        <AddSkill />
      </Grid.Column>
      <Grid.Column>
        <Input
          placeholder="Search"
          value={searchValue}
          onChange={searchValueChange}
          size="small"
        />
        <br />
        <br />
        <Query query={GET_SKILLS}>
          {({ data, loading, error }) => {
            if (loading) return <Dimmer active><Loader /></Dimmer>;
            if (error) return <p>No Skills!</p>;

            if (data.skills) {
              return data.skills.filter(searchFilter).map((skill, index) => (
                <Label image
                  key={`${index}-${skill.name}`}
                  style={{ marginBottom: "1rem" }}
                  size="medium"
                >
                  <Image avatar spaced='right' src={skill.icon} />
                  {skill.name}
                  <DeleteSkill id={skill.id} />
                </Label>
              ));
            }
          }}
        </Query>
      </Grid.Column>
    </Grid>
  );
};

export default Skills;
