import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './authContext';

const PrivateRoute = ({component: Component, ...rest}) =>(
  <AuthConsumer>
    {({ authenticated }) => {
      if (authenticated) {
        return <Route {...rest } render={props => <Component {...props}/> } />
      }
      return <Redirect to='/'/>
    }}
  </AuthConsumer>
)

export default PrivateRoute;