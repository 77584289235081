import React from "react";

import Logout from "../../components/Logout";
import Skills from "./components/Skills";
import {
  Container,
  Header,
  Divider,
  Segment,
  Grid
} from "semantic-ui-react";

const DashboardPage = props => (
  <Container style={{ padding: "1rem" }}>
    <Grid>
      <Grid.Column floated="left">
        <Header as="h1">Dashboard</Header>
      </Grid.Column>
      <Grid.Column floated="right" width={3} textAlign="right">
        <Logout />
      </Grid.Column>
    </Grid>
    <Divider />
    <Header as="h3">Skills</Header>
    <Segment>
      <Skills />
    </Segment>
  </Container>
);

export default DashboardPage;
