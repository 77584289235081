const scaledFixedRatioImage = (img, width) => {
  console.log('Scaled image', img.width);
  const scaleFactor = width / img.width;
  console.log(scaleFactor)
  return {
    width,
    height: img.height * scaleFactor,
  }
}

export const compress = (e) => {
  return new Promise((res, rej) => {
    const width = 100;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = async (event) => {
      const img = new Image();

      img.src = event.target.result;

      img.onload = () => {
        const elem = document.createElement('canvas');
        const imageDimensions = scaledFixedRatioImage(img, width);
        elem.width = width;
        elem.height = imageDimensions.height;
        const ctx = elem.getContext('2d');

        ctx.drawImage(img, 0, 0, width, imageDimensions.height);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });

          res(file);
        }, 'image/jpeg', 1);
      };

      reader.onerror = error => {
        console.log(error)
        rej(error);
      };
    };
  });
}