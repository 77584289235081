import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./config";
import Auth from "./components/Auth";

import App from "./App";

// Removed due to graphql library update
const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER,
    fetchOptions: {
      // mode: process.env.NODE_ENV !== 'production' ? 'cors' : 'no-cors',
      mode: "cors"
    },
    headers: {
      authorization: localStorage.getItem("token"),
      "client-name": "NRagone [web]",
      "client-version": "1.0.0"
    }
  })
});

new firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Auth>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth>
  </ApolloProvider>,
  document.getElementById("root")
);
