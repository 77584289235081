import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { Input, Dimmer, Loader } from 'semantic-ui-react';

import { GET_SKILLS } from "./Skills";
import Uploader from "../../../components/FileUploader";

export const ADD_SKILLS = gql`
  mutation AddSkills($skills: [addSkillInput]) {
    addSkills(skills: $skills) {
      name
      id
      icon
    }
  }
`;

export const AddSkill = () => {
  const [skill, setSkill] = useState({ name: "", icon: "" });
  const [loading, setLoading] = useState(false);

  return (
    <Mutation
      mutation={ADD_SKILLS}
      update={(cache, { data: { addSkills } }) => {
        const { skills } = cache.readQuery({ query: GET_SKILLS });
        cache.writeQuery({
          query: GET_SKILLS,
          data: { skills: skills.concat(addSkills) }
        });
      }}
    >
      {addSkills => loading ? <Dimmer active><Loader /></Dimmer> : (
        <div>
          <label>New Skill</label>
          <br />
          <Input
            name="name"
            placeholder="name"
            value={skill.name}
            onChange={e => setSkill({ ...skill, name: e.target.value })}
            style={{ padding: ".2rem .5rem" }}
          />
          <br />
          <br />
          <Uploader
            onFileSelected={file => {
              setSkill({ ...skill, icon: file.preview });
            }}
          />
          <br />
          <br />
          <button
            type="button"
            onClick={async () => {
              setLoading(true);
              await addSkills({ variables: { skills: [skill] } })
              setLoading(false);
              setSkill({ name: "", icon: "" });
            }
            }
            disabled={!skill.name || !skill.icon}
          >
            Add
          </button>
        </div>
      )}
    </Mutation>
  );
};

export default AddSkill;
