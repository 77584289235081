
const Data = {
    menu: [
      {link: '#home', caption: 'Home'},
      {link: '#work', caption: 'Work'},
      {link: '#skills', caption: 'Skills'},
      {link: '#education', caption: 'Education'},
      {link: '#contact', caption: 'Contact'},
    ]
}

export default Data;