import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../authContext';

const CallbackPage = (props) => (
  <AuthConsumer>
    {
      ({authenticated}) => {
        if (authenticated) {
          return <Redirect to='/dashboard' />
        }
        return <Redirect to='/' />
      }
    }
  </AuthConsumer>
)

export default CallbackPage;