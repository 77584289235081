import React from 'react';
import { AuthConsumer } from '../../authContext';

import './Login.css';

const Login = (props) => (
  <AuthConsumer>
    {
      ({ initiateLogin }) => {

        if (props.children) return <span className="login-btn" onClick={initiateLogin}>{props.children}</span>
        
        return <button onClick={initiateLogin}>Login</button>
      }
    }
  </AuthConsumer>
);

export default Login;