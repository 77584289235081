import * as React from "react";
import { gql } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

//Components
import Menu from "../components/Menu/Menu";
import TimeLine from "../components/TimeLine/TimeLine";
import Skills from "../components/Skills/Skills";
import Login from "../components/Login";

//Data
import Data from "../data/Data";

//Styles
import "normalize.css";
import "../App.css";

import "../components/Logo/Logo.css";

const GET_JOBS = gql`
  query getJobs {
    jobs {
      id
      company {
        name
        logo
        link
      }
      startDate
      endDate
      project
      roleName
      roleDescription
    }
  }
`;

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount () {
    /* this.container = document.getElementsByClassName('container');
    this.container[0].style.setProperty('opacity', '0');

    this.footer = document.getElementsByClassName('footer');
    this.setState({containerPaddingBottom: this.footer[0].offsetHeight}); */
    /**
     * The lifecycle of an HTML page has three important events:

      DOMContentLoaded – the browser fully loaded HTML, and the DOM tree is built, but external resources like pictures <img> and stylesheets may be not yet loaded.
      load – the browser loaded all resources (images, styles etc).
      beforeunload/unload – when the user is leaving the page.
     */
    /* document.addEventListener('DOMContentLoaded', () => {
      this.container[0].style.setProperty('opacity', '1');
      this.setState({loading: false});
    }); */
  }

  render () {
    return (
      <div className="App">
        {/* this.state.loading ? <Loading/> : '' */}
        <div
          className="container"
          style={{ paddingBottom: this.state.containerPaddingBottom }}
        >
          <section
            id="home"
            className="section"
            style={{ backgroundImage: `url('./img/background6.jpg')` }}
          >
            <article className="centered-article full-width">
              <h1 className="font-keys -normal -lg">
                Nico<Login>l</Login>as Ragone
              </h1>
              <h3 className="">Software Engineer</h3>
              <br />
              <br />
              {/* <h3 className="font-optimus">
                “There is nothing noble in being superior to your fellow man;
                true nobility is being superior to your former self.”
                <br /> Ernest Hemingway
              </h3> */}
            </article>
          </section>
          <section
            id="work"
            className="section"
            style={{ backgroundImage: `url('./img/background1.jpg')` }}
          >
            <article className="centered-article full-width">
              <h1>Work Experience</h1>
              <Query query={GET_JOBS}>
                {({ loading, error, data }) => {
                  if (loading) return <div>Loading...</div>;
                  if (error)
                    return (
                      <p>
                        No Jobs! <br /> {error.message}
                      </p>
                    );

                  return (
                    <TimeLine
                      points={data.jobs}
                      sort="desc"
                      sortKey="startDate"
                    />
                  );
                }}
              </Query>
            </article>
          </section>
          <section id="skills" className="section" style={{ backgroundImage: `url('./img/background7.jpg')` }}>
            <article className="centered-article full-width">
              <h1>Tech Stack</h1>
              <Skills />
            </article>
          </section>
          <section
            id="education"
            className="section"
            style={{ backgroundImage: `url('./img/background4.jpg')` }}
          >
            <article className="centered-article full-width">
              <h1>Education</h1>
              <p>
                <b>Computer Engineer</b>: National University of Tucumán
              </p>
              <p>
                <b>Electronic Technician</b>: Technical Institute Lorenzo Massa
              </p>
            </article>
          </section>
          <section id="contact" className="section">
            <article className="centered-article no-bg">
              <h3>
                <span className="fa fa-envelope" />
                <a href="mailto:nico16890@gmail.com">
                  &nbsp;nico16890@gmail.com
                </a>
              </h3>
              <h3>
                <span className="fa fa-skype" />
                &nbsp;nico_16890
              </h3>
              <h3>
                <span className="fa fa-linkedin-square" />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/nicol%C3%A1s-ragone-75198b88/"
                >
                  &nbsp;Nicolas Ragone
                </a>
              </h3>
              <h3>
                <span className="fa fa-github-square" />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/nragone"
                >
                  &nbsp;nragone
                </a>
              </h3>
            </article>
          </section>
          <footer className="footer">
            <Menu items={Data.menu} />
          </footer>
        </div>
      </div>
    );
  }
}

export default HomePage;
