import React, { Component } from 'react';
import { AuthProvider } from '../authContext';

import firebase from 'firebase/compat/app';

class Auth extends Component {
  
  state = {
    isAuthenticating: true,
    authenticated: false,
    user: {
      role: 'visitor'
    },
    accessToken: ''
  }

  getAuthUser = () => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function(user) {
        
        if (user) {
          resolve(user);
        } else {
          reject('user not logged in')
        }
      })
    }) 
  }

  handleUserAuthChange = (user) => {
    if(user) {
      this.setState({
        isAuthenticating: false,
        authenticated: true,
        accessToken: 'token',
        user
      });
    } else {
      this.setState({
        isAuthenticating: false,
        authenticated: false,
        accessToken: '',
        user: {
          role: 'visitor'
        }
      });
    }
  }

  handleUserAuthChangeError = (err) => {
    console.log("Error auth user", err);
    this.setState({
      isAuthenticating: false,
      authenticated: false,
      accessToken: '',
      user: {
        role: 'visitor'
      }
    });
  }

  addAuthenticationListener = () => {
    this.authListenerUnsubscribe = firebase.auth().onAuthStateChanged(this.handleUserAuthChange, this.handleUserAuthChangeError)
  }

  componentDidMount() {
    this.addAuthenticationListener();
  }

  componentWillUnmount() {
    this.authListenerUnsubscribe();
  }

  initiateLogin = () => {
    console.log("Initiate Login process")
    window.location = '/login';
  }

  logout = () => {
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      console.log("Logout")
    }).catch(function(error) {
      // An error happened.
      console.log("Logout Error", error)
    });
  }

  handleAuthentication = () => {
    console.log("Handle Authentication Success")
  }

  setSession = (authResult) => {
    console.log("Set Session")
  }

  render() {

    if (this.state.isAuthenticating) return null;

    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      logout: this.logout,
      handleAuthentication: this.handleAuthentication
    }

    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    )
  }
}

export default Auth;