import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "./authContext";

import PrivateRoute from "./PrivateRoute";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import CallbackPage from "./pages/CallbackPage";
import LoginPage from "./pages/LoginPage";

import 'semantic-ui-css/semantic.min.css';

const App = props => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <PrivateRoute path="/dashboard" component={DashboardPage} />
    <Route
      path="/login"
      render={props => (
        <AuthConsumer>
          {({ authenticated }) => {
            if (authenticated) {
              return <Redirect to="/dashboard" />;
            }

            return <LoginPage {...props} />;
          }}
        </AuthConsumer>
      )}
    />
    <Route path="/callback" component={CallbackPage} />
  </Switch>
);

export default App;
