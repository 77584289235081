import React, { useState } from "react";
import { gql } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";

import { Icon, Dimmer, Loader } from 'semantic-ui-react';

import { GET_SKILLS } from "./Skills";

export const DELETE_SKILL = gql`
  mutation DeleteSkill($id: String!) {
    deleteSkill(id: $id)
  }
`;

export const DeleteSkill = ({ id }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Mutation
      mutation={DELETE_SKILL}
      update={(cache, { data: deleteSkill }) => {
        const { skills } = cache.readQuery({ query: GET_SKILLS });
        cache.writeQuery({
          query: GET_SKILLS,
          data: { skills: skills.filter(skill => skill.id !== id) }
        });
      }}
    >
      {deleteSkill => loading ? <Dimmer active><Loader /></Dimmer> : (
        <Icon name='delete' onClick={async () => {
          setLoading(true);
          await deleteSkill({ variables: { id } })
          setLoading(false);
        }} />
      )}
    </Mutation>
  );
};

export default DeleteSkill;
